export const TEAM_URL = "https://w.amazon.com/bin/view/FinanceTechnology/Receivables/Billing/Home/"
export const ACCESS_NOTICE_URL = "https://w.amazon.com/bin/view/Permissions_Tool/Teams/ManagerModelTeams"
export const ACCESS_NOTICE = "Access for specific channels on zoom is no longer supported via sim-ticketing. We have moved to a Manager Approval Model"

export const TRX_NUMBER_FIELD = "Transaction Number"
export const CURRENCY_FIELD = "Currency"
export const STAGE_FIELD = "Stage"
export const CREATION_DATE_FIELD = "Creation Date"
export const AMOUNT_FIELD = "Amount"
export const ORG_NAME_FIELD = "Organization Name"
export const NAME_FIELD = "Name"
export const TOTAL_AMOUNT_FIELD = "Total Amount"
export const AMOUNT_REMAINING_FIELD = "Amount Remaining"
export const TRX_DATE_FIELD = "Transaction Date"
export const GL_DATE_FIELD = "GL Date"
export const STATUS_FIELD = "Status"
export const DATE_RANGE_FIELD = "Date Range"
export const CONTRA_COGS_AGREEMENT_URL ="https://contra-cogs.aka.amazon.com/agreements/"


